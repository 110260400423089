<template>
    <div class="transaction-list">
        <transaction-table />
    </div>
</template>

<script>
import TransactionTable from '@/components/transactions/TransactionTable';

export default {
    components: { TransactionTable },
};
</script>